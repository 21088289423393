import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import {ContentWrapper} from "../components/styles/global-styles"
import {privacyNotice} from "../data/legal-data"

const FaqsPage = () => (
  <Layout>
    <Seo title="Privacy Notice" />
    <FaqContainer>
      <ContentWrapper isLarge="true">
        <div dangerouslySetInnerHTML={{__html: privacyNotice.content}}></div>
      </ContentWrapper>
    </FaqContainer>
  </Layout>
)

export default FaqsPage

const FaqContainer = styled.div`
background: #000;
padding: 60px 5rem;
display: flex;
justify-content: center;
color: #fff;
`

